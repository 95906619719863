import { combineReducers } from 'redux';
import { fetchCart } from './cart/cartReducer';
import { createOrderReducer } from './createOrder/createOrderReducer';
import { FCReducer } from './FoodCourt/reducer';
import { menuFilterReducer } from './menuFilter/reducer';
import { OrdersReducer } from './OrderStatus/reducer';
import fetchOrderTypeOrderTime from './orderType/orderTypeReducer';
import fetchRestaurantSetting from './RestSetting/restSettingReducer';
import { fetchuserAddressReducer } from './userAddress/userAddressReducer';
import fetchUserDetails from './userDetails/userDetailsReducer';
import RecommendationsReducer from './recommendation/reducer';
import FoodcourtRestaurantsReducer from './foodcourtRestaurants/reducer';
import restReducer from './home/reducer';
import { fcSettingReducer } from './FoodCourt/reducer';

// Action to reset complete redux state
const USER_LOGOUT = 'USER_LOGOUT';

export const resetReducerAction = () => {
  return {
    type: USER_LOGOUT,
  };
};

const appReducer = combineReducers({
  orderTypeOrderTime: fetchOrderTypeOrderTime,
  restSetting: fetchRestaurantSetting,
  userDetails: fetchUserDetails,
  OrdersReducer,
  FCReducer,
  recommendations: RecommendationsReducer,
  cart: fetchCart,
  menuFilterReducer,
  userAddress: fetchuserAddressReducer,
  createdOrder: createOrderReducer,
  restaurantList: restReducer,
  foodcourtRestaurants: FoodcourtRestaurantsReducer,
  fcSettings:fcSettingReducer
});

const rootReducers = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducers;
