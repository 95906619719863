import { CART_LOADING, UPDATE, UPDATE_FC_SETTINGS } from './type';
const initialState = {
  loaded: false,
  loading: false,
};
export const FCReducer = (state = initialState, action) => {
  const data = action.payload;
  if (action.type == UPDATE) return { ...data, loaded: true };
  if (action.type == CART_LOADING) return { ...state, loading: action.payload };
  else return { ...state };
};


export const fcSettingReducer = (state = {}, action) => {
  const data = action.payload;
  if (action.type == UPDATE_FC_SETTINGS) return { ...data};
  else return { ...state };
};
