import queryString from 'query-string';
import { lazy, useEffect, useState } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import { Provider } from 'react-redux';
import errorLogger from './analytics/ErrorLogger';
import './css/app.css';
import './css/global.css';
import { externalQrScan } from './functions/externalQRScan';
import facebookPixel from './functions/facebookPixel';
import store from './redux/store';
import Routers from './router';
import { newRelic } from './utils/newRelic';
import GlobalContext from './GlobalContext';
import { getSessionItem, setSessionItem } from './webStorage/sessionStorage';
import { v4 as uuidv4 } from 'uuid';
import { setLocalItem } from './webStorage/localStorage';
import { detect } from 'detect-browser';
import { setCheckCartToken, getCartTokenLS } from './functions/getCartToken';
import { getMicrosoftClarityScript } from './functions/getMicrosoftClarityScript';
import SuspenseWrapper from './component/common/suspenseWrapper';
import mixpanel from 'mixpanel-browser';
import { processQRCode } from './functions/qrExpiredUtils';
const QrExpired = lazy(() =>
  import(/* webpackChunkName:"QrExpiredPopup"*/ './component/popUp/qrExpired')
);

newRelic();
window.__bglogger = errorLogger();
mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN, { debug: true });
window.__bgtracker = window.__bgtracker || {};
window.__bgtracker.track = (hitName, data = {}) => {
  // Send the event to Mixpanel
  try {
    mixpanel.track(hitName, data);
  } catch (error) {}
};

function App() {
  const { REACT_APP_FACEBOOK_PIXEL_ID, REACT_APP_MICROSOFT_CLARITY_ID } = process.env;
  const [nameId, setnameId] = useState('');
  const [otFromUrl, setotFromUrl] = useState('');
  const [redirectFromQr, setredirectFromQr] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(['cart_token']);
  const [scheduleTime, setScheduledTime] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showQrExpired, setShowQrExpired] = useState(0);

  // const cartTokenFromLS = getCartTokenLS('');

  // url param queries
  let urlSearchQuery = queryString.parse(window.location.search);

  // Recommendatons sessionId
  useEffect(() => {
    const sessionIdFromSession = getSessionItem('sessionId');
    const sessionIdFromQuery = urlSearchQuery?.sessionId;

    // Do not set sessionId if already present in session storage
    if (!sessionIdFromSession) {
      setSessionItem('sessionId', uuidv4());
    }

    // Set session id from query params if present
    if (sessionIdFromQuery) {
      setSessionItem('sessionId', sessionIdFromQuery);
    }
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.textContent = getMicrosoftClarityScript(REACT_APP_MICROSOFT_CLARITY_ID);
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);
  useEffect(() => {
    removeCookie('cart_token', { path: '/' });
    const cartTokenFromLS = getCartTokenLS('');
    const cartToken = setCheckCartToken(cartTokenFromLS);
    // setCookie('cart_token', cartToken, { path: '/' });
  }, []);
  useEffect(() => facebookPixel(REACT_APP_FACEBOOK_PIXEL_ID), [REACT_APP_FACEBOOK_PIXEL_ID]);
  const fnBrowserDetect = () => {
    const browser = detect();
    setLocalItem('browser', browser.name);
  };
  useEffect(() => {
    fnBrowserDetect();
  }, []);
  useEffect(() => {
    let urlData = window.location.href;
    urlData = JSON.stringify(urlData).replace('?', '?&');
    if (urlData.includes('menu.php')) {
      urlData = JSON.parse(urlData);
      let queries = queryString.parse(urlData);
      if (queries?.rest_name) {
        setnameId(queries?.rest_name);
      }
      if (queries.date && queries.slot) {
        setScheduledTime({ date: queries.date, slot: queries.slot });
      }
      if (queries.ot) {
        setotFromUrl(queries.ot);
      }
    } else if (urlData.includes('set_restaurant_qr.php')) {
      setLoading(true);
      externalQrScan(urlData).then(({ redirectedURL, tableIdFromUrl, tableNo, qrOrderingPlan }) => {
        if (tableIdFromUrl && tableNo) {
          setCookie('tableId', tableIdFromUrl, { path: '/' });
          setCookie('tableNo', tableNo, { path: '/' });
        }

        const result = processQRCode(urlData, qrOrderingPlan, setShowQrExpired);
        if (result === 'early_return') {
          return setLoading(false);
        }
        setredirectFromQr(redirectedURL);
      });
    }
  }, []);

  return (
    <>
      <GlobalContext.Provider value={{ loading, setLoading }}>
        <CookiesProvider>
          <Provider store={store}>
            <div
              className='container-fluid main_app pl-0 pr-0 select-none'
              style={{ minHeight: `${window.innerHeight}px` }}>
              <Routers
                redirect={nameId.toLowerCase()}
                ot={otFromUrl}
                redirectFromQr={redirectFromQr}
                scheduleTime={scheduleTime}
              />
            </div>
            {!!showQrExpired ? (
              <SuspenseWrapper>
                <QrExpired
                  close={() => setShowQrExpired(false)}
                  redirectToScanQr={() => setredirectFromQr('/qr')}
                  showSecondaryButton={false}
                  triggerBackgroundClose={false}
                  headerType={showQrExpired}
                />
              </SuspenseWrapper>
            ) : null}
          </Provider>
        </CookiesProvider>
      </GlobalContext.Provider>
    </>
  );
}

export default App;
